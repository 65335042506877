export const imageWithCTAObject = {
  name: 'Champion & Friend Recommendation via Aklamio',
  headline: 'Prämie für jeden Kredit: Freunde werben!',
  image: {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'vh9g3jzzo9qx',
        },
      },
      id: '5PDch9Lyz7szvaOPcK86DE',
      type: 'Asset',
      createdAt: '2021-11-16T08:53:49.748Z',
      updatedAt: '2022-01-04T17:28:26.400Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 17,
      locale: 'de-DE',
    },
    fields: {
      title: 'smava_man_01.png',
      file: {
        url: '/assets/visuals/smavaHero@3x.webp',
        details: {
          size: 58000,
          image: {
            width: 1200,
            height: 1200,
          },
        },
        fileName: 'smavaHero@3x.webp',
        contentType: 'image/webp',
      },
    },
  },
  text: 'Empfehlen Sie smava Ihren Freunden. Als Dankeschön bekommen Sie nach jeder Auszahlung eine Geldprämie – Dieses Angebot gilt nur für kurze Zeit!',
  ctaText: 'Jetzt Prämie sichern',
  ctaUrl: 'https://smava.aklamio.com/',
};
