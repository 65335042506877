import Image from 'next/legacy/image';
import { RichText } from 'components/ContentfulContent/RichText/RichText';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { AssetFile } from 'contentful';

const GridContainer = styled(Container)`
  display: grid;
  column-gap: 11%;
  row-gap: ${(props) => props.theme.spacing(4)};
  grid-template-columns: 1fr 40%;
  ${(props) => props.theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`;

const ColoredBackground = styled('div')`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) => props.theme.breakpoints.down('sm')} {
    order: 2;
  }
`;

const ImageWrapper = styled('div')`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 0 -${(props) => props.theme.spacing(2)};
  }
`;

export type TextImageProps = any;
export default function TextImage(data: TextImageProps) {
  const imageFile = data?.image?.fields?.file as AssetFile;
  const plainImgUrl = imageFile?.url as string;
  const imgUrl = plainImgUrl?.startsWith('//')
    ? `https:${plainImgUrl}`
    : plainImgUrl;
  const altText = data?.altTextForImage || '';
  const { width, height } = imageFile.details!.image!;
  return (
    <ColoredBackground>
      <Box py={{ xs: 6, sm: 6, md: 14, lg: 14, xl: 14 }} pt={{ xs: 0 }}>
        <GridContainer maxWidth="lg">
          <TextWrapper>
            <Typography variant="h2" marginBottom={4}>
              {data?.headline}
            </Typography>
            <RichText document={data?.text}></RichText>
          </TextWrapper>
          <ImageWrapper>
            <Image
              src={imgUrl}
              blurDataURL={imgUrl}
              height={height}
              width={width}
              alt={altText}
              layout="responsive"
            />
          </ImageWrapper>
        </GridContainer>
      </Box>
    </ColoredBackground>
  );
}
